import React, { useEffect, useState } from 'react';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import WelcomeBanner from '../../../layouts/WelcomeBanner';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicNavLogo from '../../../components/DynamicNavLogo';
import CustomTabs from '../../../layouts/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAtsListReset, retrieve_exchange_ats_list } from '../../../utils/actions';
import * as app_consts from '../../../utils/constants';
// import CustomerTypeModal from '../../components/modals/CustomerTypeModal';

const MarketsDashboard = () => {
  // const [showModal, setShowModal] = useState(false);
  const { entity_id, entity_mid } = useParams();
  const dispatch = useDispatch();
  const currentUrl = window.location.href;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Automated Trading Platform');
  const [isDataFetched, setIsDataFetched] = useState(false);

  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;

  const [ats_data_loading, setAtsDataLoading] = useState(true);
  const [ats_data_error, setATSDataError] = useState(true);
  const [ats_data, setAtsData] = useState([]);
  const [ats_list, setAtsList] = useState([]);

  // Code that Runs When Page First Opens
  useEffect(() => {
    dispatch(retrieve_exchange_ats_list());
  }, []);

  // Exchange ATS List Listener
  const ats_listener = useSelector((state) => state.retrieveAtsList);
  // 
  useEffect(() => {
    if (ats_listener.status !== app_consts.IDLE)
    {
      if (ats_listener.isLoading)
      {
        setAtsDataLoading(true);
      }
      else
      {
        if (ats_listener.error)
        {
          setATSDataError(ats_listener.error);
        }
        else if (ats_listener.data)
        {
          setAtsData(ats_listener.data);
        }
        dispatch(retrieveAtsListReset());
        setAtsDataLoading(false);
      }
    }
  }, [ats_listener]);

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const tabs = [
    { title: 'Automated Trading Platform', disabled: ats_data_loading },
    { title: 'Offer Management', disabled: !isDataFetched },
    { title: 'Bid Management', disabled: !isDataFetched },
    { title: 'Receipts Management', disabled: !isDataFetched },
  ];

  return (
    <>

      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>

      <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
        <div class="flex items-center flex-shrink-0  mr-6">

          <a href='/home' className='flex items-center pl-2.5'>
            <DynamicNavLogo height='50' width='150' />
          </a>
        </div>
        <div class="block lg:hidden">
          <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
            <a href={`/exchange/${entity_id}/${entity_mid}`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white  group">
              Exchange Dashboard
            </a>
          </div>
          <div>
            <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0">Logout</a>
          </div>
        </div>
      </nav>


      <div className="lg-full lg:w-10/12 m-auto">
        <div className="p-4 rounded-lg dark:border-gray-700">

          <div className="relative">
            <div>
              <h5 class="text-3xl mr-3 font-semibold dark:text-white">Markets</h5>
            </div>
            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            {
              activeTab === "Automated Trading Platform" && (
                <>
                  {
                    ats_data_loading ?
                      <>
                        <div className="text-center my-2">
                          <div className="text-center my-6">
                            <span colSpan="8" className="text-gray-700 py-4 my-2">
                              Loading Automated Trading System Data
                            </span>
                          </div>
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Please wait...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {
                          ats_data_error ?
                            <>
                              <tr className='flex items-center w-full justify-center'>
                                <td colSpan="8" className="w-full text-center py-4">
                                  <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                    {ats_data_error}
                                  </span>
                                </td>
                              </tr>
                            </>
                            :
                            <>

                            </>
                        }
                      </>
                  }
                </>
              )
            }
          </div>
        </div>
      </div>

      <div className='p-4 sm:ml-64'>
        <div className='p-4 rounded-lg dark:border-gray-700'>
          <WelcomeBanner bannerTitle="Markets Dashboard" />

          <div className='grid md:grid-cols-2 gap-4 mb-4'>

            <a
              href={`/markets/${entity_id}/${entity_mid}/offer_management`}
              class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
            >
              <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              </div>
              <div class='flex flex-col ml-2'>
                <span class='text-lg font-bold leading-5 text-green-600'>
                  Offer Management
                </span>
                <span class='text-sm text-gray-500 hidden md:block'>
                  Manage Markert Offers
                </span>
              </div>
            </a>

            <a
              href={`/markets/${entity_id}/${entity_mid}/bid_management`}
              class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
            >
              <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              </div>
              <div class='flex flex-col ml-2'>
                <span class='text-lg font-bold leading-5 text-green-600'>
                  Bid Management
                </span>
                <span class='text-sm text-gray-500 hidden md:block'>
                  Manage Markert Bids
                </span>
              </div>
            </a>
            <a
              href={`/markets/${entity_id}/${entity_mid}/provision_receipt_management`}
              class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
            >
              <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              </div>
              <div class='flex flex-col ml-2'>
                <span class='text-lg font-bold leading-5 text-green-600'>
                  Provisional Receipt Management
                </span>
                <span class='text-sm text-gray-500 hidden md:block'>
                  Manage Provisional Receipts
                </span>
              </div>
            </a>

            <a
              href={`/markets/${entity_id}/${entity_mid}/ats_management`}
              className="each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer"
            >
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-6 h-6'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
                  />
                </svg>
              </div>
              <div class='flex flex-col ml-2'>
                <span className='text-lg font-bold leading-5 text-green-600'>
                  Automated Trading System
                </span>
                <span class='text-sm text-gray-500 hidden md:block'>
                  View Trades on the live market
                </span>
              </div>
            </a>

          </div>
        </div>
      </div>

      {/* <CustomerTypeModal showModal={showModal} closeModal={closeModal} /> */}
    </>
  );
};

export default MarketsDashboard;
