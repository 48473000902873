import axios from 'axios';

// const API_BASE_URL = 'https://api.zamace.co.zm/api/v1/';
// const API_BASE_URL = 'http://localhost:4000/api/v1/';
const API_BASE_URL = 'https://devapi.zamace.co.zm/api/v1/';
// const API_BASE_URL = 'http://192.168.255.20:4000/api/v1/';

//API
const apiService = axios.create({
  baseURL: API_BASE_URL,
});

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 402)
    )
    {
      const originalRequest = error.config;
      if (!originalRequest._retry)
      {
        originalRequest._retry = true;
        const token = localStorage.getItem('authToken');
        // const refreshResponse = await apiService.get(
        //   '/accounts/refresh_session',
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );

        // if (refreshResponse.status === 200)
        // {
        // localStorage.setItem('authToken', refreshResponse.data.token);
        apiService.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        originalRequest.headers[
          'Authorization'
        ] = `Bearer ${token}`;
        return apiService(originalRequest);
        // }
      }
    }
    return Promise.reject(error);
  }
);

export default apiService;
