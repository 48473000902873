import React, { useEffect, useRef, useState } from 'react';
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import generatePDF from 'react-to-pdf';
import DynamicNavLogo from '../../components/DynamicNavLogo';
import { format } from 'date-fns';
import * as app_consts from '../../utils/constants';
import { convertUnderscoreToTitleCase } from '../../utils/functionalUtils';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import PDFSVGLogo from '../../components/PDFSVGLogo';

const SpecificOutboundWarehouseReceiptStatement = ({ data, }) => {

    const targetRef = useRef();
    const [outboundShipmentsData, setOutboundShipmentsData] = useState(data);
    const [total, setTotal] = useState({ truck_loads: "", no_of_bags: "", quantity: "" });
    const [dispatched_to_data, setDispatchedToData] = useState([]);
    const [detailedShpiments, setDetailedShipments] = useState([]);

    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        if (data)
        {
            setOutboundShipmentsData(data);
        }
    }, [data]);


    // Goods Received UseEffect
    useEffect(() => {
        if (outboundShipmentsData?.outbound_shipments)
        {
            // Create New Map
            // New Map Data
            let dispatched_to_map_data = [];
            outboundShipmentsData?.outbound_shipments?.map((outbound) => {
                if (!dispatched_to_map_data?.includes(outbound?.dispatched_to))
                {
                    dispatched_to_map_data?.push(outbound?.dispatched_to);
                }
            });
            setDispatchedToData(dispatched_to_map_data);
            const sorted_outbound = outboundShipmentsData?.outbound_shipments?.sort((a, b) => {
                let dateA = new Date(a.date_dispatched);
                let dateB = new Date(b.date_dispatched);
                return dateA - dateB; // Ascending order
            });
            setDetailedShipments(sorted_outbound);
        }
    }, [outboundShipmentsData]);

    const getTotalCurrentQuantityByLocation = (location) => {
        const total = detailedShpiments?.reduce((sum, obj) => {
            if (obj.dispatched_to === location)
            {
                return sum + (parseFloat(obj?.current_quantity - obj.new_quantity) || 0);
            }
            return sum;
        }, 0);
        return total.toFixed(2);
    };

    return (
        <>
            {
                outboundShipmentsData && (
                    <Document title="Goods Received Notes Statement">
                        <Page size="A4" style={{
                            flexDirection: "column",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            fontFamily: "Lato"
                        }}>
                            <View fixed>
                                <View style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 5,
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        ViewTransform: "uppercase",
                                        width: "50%",
                                        paddingLeft: 20,
                                    }}>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", position: 'relative' }}>
                                            <PDFSVGLogo height='100px' width='100%' />
                                        </View>
                                    </View>

                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        textTransform: "uppercase",
                                        width: "50%",
                                        marginRight: 10,
                                    }}>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            marginBottom: 5,
                                            marginTop: 5
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 12 }}>Warehouse Operator:</Text>
                                            <Text style={{ fontWeight: 600, fontSize: 12, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{outboundShipmentsData.warehouse_operator_name && outboundShipmentsData.warehouse_operator_name}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            marginBottom: 5,
                                            marginTop: 5
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 12 }}>Warehouse Name:</Text>
                                            <Text style={{ fontWeight: 600, fontSize: 12, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{outboundShipmentsData.warehouse_name && outboundShipmentsData.warehouse_name}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            marginBottom: 5,
                                            marginTop: 5
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 12 }}>Warehouse ID:</Text>
                                            <Text style={{ fontWeight: 600, fontSize: 12, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{outboundShipmentsData.warehouse_unique_id && outboundShipmentsData.warehouse_unique_id}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 12 }}>Date Generated:</Text>
                                            <Text style={{ fontWeight: 600, letterSpacing: 1, fontSize: 12, color: "#252629", lineHeight: 1.2 }}>{format(new Date(), 'yyyy-MM-dd  HH:mm:ss')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View fixed>
                                <View style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 25,
                                }}>
                                    <Text style={{ fontWeight: 600, paddingLeft: 6, fontSize: 25, color: "#000", wordSpacing: "0.05px", lineHeight: 0.2, }}>
                                        Outbound Shipments Statement
                                    </Text>
                                </View>
                            </View>
                            <View style={{
                                width: "100%",
                                paddingRight: 5,
                                paddingTop: 5,
                                paddingBottom: 5,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "start",
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 5
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>DEPOSITOR</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 14 }}>{outboundShipmentsData.producer_type ? <>{outboundShipmentsData.owner_name !== app_consts.INDIVIDUAL.toLowerCase() ? outboundShipmentsData?.producer_entity_name.toUpperCase() : outboundShipmentsData?.producer_contact_name.toUpperCase()}</> : ""}</Text>
                                    </View>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: 5,
                                        paddingLeft: 6
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>DEPOSITOR ID</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 14 }}>{outboundShipmentsData.producer_unique_id && (outboundShipmentsData.producer_unique_id.toUpperCase())}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{
                                width: "100%",
                                paddingRight: 5,
                                paddingTop: 5,
                                paddingBottom: 5,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "start",
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15,
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 5
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>WAREHOUSE RECEIPT NUMBER</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 10, marginTop: 5 }}>{outboundShipmentsData.receipt_number ? outboundShipmentsData?.receipt_number.toUpperCase() : ""}</Text>
                                    </View>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: 5,
                                        paddingLeft: 6
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>COMMODITY</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 14 }}>{outboundShipmentsData.commodity ? convertUnderscoreToTitleCase(outboundShipmentsData.commodity.toUpperCase()) : "---"}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ paddingRight: "30px", paddingLeft: 30, marginTop: 20 }}>

                                {
                                    dispatched_to_data?.map((dispatch, index) => {
                                        return (
                                            <>
                                                <View style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderTopWidth: 2,
                                                    borderStyle: "solid",
                                                    borderColor: "#D1D1D1",
                                                    marginTop: 15,
                                                }} key={index}>
                                                    <View style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        ViewTransform: "uppercase",
                                                        width: "50%",
                                                        padding: 3,
                                                        marginTop: 5,
                                                    }}>
                                                        <View style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            paddingLeft: 5
                                                        }}>
                                                            <Text style={{ fontWeight: 400, fontSize: 14, color: "#000" }}>Dispatched to: {` `}</Text>
                                                            <Text style={{ fontWeight: 600, fontSize: 14 }}>{dispatch}</Text>
                                                        </View>
                                                    </View>
                                                </View>

                                                <View style={{ paddingRight: 10, paddingLeft: 10, marginTop: 5, marginBottom: 15 }}>
                                                    <View style={{
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        marginTop: 5,
                                                        borderRadius: 4,
                                                        borderStyle: "solid",
                                                        borderColor: "#e5e9f2",
                                                    }}>
                                                        <View style={{
                                                            marginTop: 5,
                                                            marginBottom: 5,
                                                            width: "100%",
                                                        }}>
                                                            <View style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                backgroundColor: "#DFDFDF",
                                                            }}>
                                                                <View style={{
                                                                    width: "20%",
                                                                    padding: 3,
                                                                }}>
                                                                    <Text style={{
                                                                        margin: "auto",
                                                                        marginTop: 5,
                                                                        color: "#302f2f",
                                                                        fontSize: 10,
                                                                        lineHeight: 1.6,
                                                                        fontWeight: 700,
                                                                        lineHeight: "normal",
                                                                        textAlign: "start",
                                                                        padding: 3
                                                                    }}>Dispatched Date:</Text>
                                                                </View>
                                                                <View style={{
                                                                    width: "20%",
                                                                    padding: 3,
                                                                }}>
                                                                    <Text style={{
                                                                        margin: "auto",
                                                                        marginTop: 5,
                                                                        color: "#302f2f",
                                                                        fontSize: 10,
                                                                        lineHeight: 1.6,
                                                                        fontWeight: 700,
                                                                        lineHeight: "normal",
                                                                        textAlign: "start",
                                                                        padding: 3
                                                                    }}>GIN No.</Text>
                                                                </View>
                                                                <View style={{
                                                                    width: "20%",
                                                                    padding: 3,
                                                                }}>
                                                                    <Text style={{
                                                                        margin: "auto",
                                                                        marginTop: 5,
                                                                        color: "#302f2f",
                                                                        fontSize: 10,
                                                                        lineHeight: 1.6,
                                                                        fontWeight: 700,
                                                                        lineHeight: "normal",
                                                                        textAlign: "start",
                                                                        padding: 3
                                                                    }}>Old Quantity (MT)</Text>
                                                                </View>
                                                                <View style={{
                                                                    width: "20%",
                                                                    padding: 3,
                                                                }}>
                                                                    <Text style={{
                                                                        margin: "auto",
                                                                        marginTop: 5,
                                                                        color: "#302f2f",
                                                                        fontSize: 10,
                                                                        lineHeight: 1.6,
                                                                        fontWeight: 700,
                                                                        lineHeight: "normal",
                                                                        textAlign: "start",
                                                                        padding: 3
                                                                    }}>New Quantity (MT)</Text>
                                                                </View>
                                                                <View style={{
                                                                    width: "20%",
                                                                    padding: 3,
                                                                }}>
                                                                    <Text style={{
                                                                        margin: "auto",
                                                                        marginTop: 5,
                                                                        color: "#302f2f",
                                                                        fontSize: 10,
                                                                        lineHeight: 1.6,
                                                                        fontWeight: 700,
                                                                        lineHeight: "normal",
                                                                        textAlign: "start",
                                                                        padding: 3
                                                                    }}>Outbound Quantity (MT)</Text>
                                                                </View>
                                                            </View>
                                                            {
                                                                detailedShpiments?.map((outbound) => {
                                                                    if (dispatch === outbound?.dispatched_to)
                                                                    {
                                                                        return (
                                                                            <>
                                                                                <View style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    borderColor: "#e5e9f2",
                                                                                    borderStyle: "solid",
                                                                                    borderBottomWidth: 1,
                                                                                }}>
                                                                                    <View style={{
                                                                                        width: "20%",
                                                                                        padding: 1,
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            marginTop: 5,
                                                                                            color: "#252b32",
                                                                                            fontSize: 10,
                                                                                            fontWeight: 400,
                                                                                            textAlign: "center",
                                                                                            padding: 1
                                                                                        }}>{outbound?.date_dispatched ? (format(new Date(outbound.date_dispatched), 'yyyy-MM-dd')) : "---"}</Text>
                                                                                    </View>
                                                                                    <View style={{
                                                                                        width: "20%",
                                                                                        padding: 1,
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            marginTop: 5,
                                                                                            color: "#252b32",
                                                                                            fontSize: 10,
                                                                                            fontWeight: 400,
                                                                                            textAlign: "center",
                                                                                            padding: 1
                                                                                        }}>{outbound?.gin_no ? outbound?.gin_no : "---"}</Text>
                                                                                    </View>
                                                                                    <View style={{
                                                                                        width: "20%",
                                                                                        padding: 1,
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            marginTop: 5,
                                                                                            color: "#252b32",
                                                                                            fontSize: 10,
                                                                                            fontWeight: 400,
                                                                                            textAlign: "center",
                                                                                            padding: 1
                                                                                        }}>{outbound?.current_quantity ? parseFloat(outbound?.current_quantity).toFixed(2) : "---"}</Text>
                                                                                    </View>
                                                                                    <View style={{
                                                                                        width: "20%",
                                                                                        padding: 1,
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            marginTop: 5,
                                                                                            color: "#252b32",
                                                                                            fontSize: 10,
                                                                                            fontWeight: 400,
                                                                                            textAlign: "center",
                                                                                            padding: 1
                                                                                        }}>{outbound?.new_quantity ? parseFloat(outbound?.new_quantity).toFixed(2) : "---"}</Text>
                                                                                    </View>
                                                                                    <View style={{
                                                                                        width: "20%",
                                                                                        padding: 1,
                                                                                    }}>
                                                                                        <Text style={{
                                                                                            marginTop: 5,
                                                                                            color: "#252b32",
                                                                                            fontSize: 10,
                                                                                            fontWeight: 400,
                                                                                            textAlign: "center",
                                                                                            padding: 1
                                                                                        }}>{outbound?.new_quantity && outbound?.current_quantity ? parseFloat(outbound?.current_quantity - outbound?.new_quantity).toFixed(2) : "---"}</Text>
                                                                                    </View>
                                                                                </View>
                                                                            </>

                                                                        );
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                detailedShpiments && detailedShpiments?.length > 0 && (
                                                                    <>
                                                                        <View style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            borderColor: "#e5e9f2",
                                                                            borderStyle: "solid",
                                                                            borderBottomWidth: 1,
                                                                        }}>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <View style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}></View>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <View style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}></View>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <View style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}></View>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 700,
                                                                                    fontWeight: 700,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>TOTAL:</Text>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 700,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>{getTotalCurrentQuantityByLocation(dispatch)}</Text>
                                                                            </View>
                                                                        </View>
                                                                    </>
                                                                )
                                                            }
                                                        </View>
                                                    </View>
                                                </View>
                                            </>
                                        );
                                    })
                                }
                            </View>
                        </Page>
                    </Document>
                )
            }
        </>
    );
};

export default SpecificOutboundWarehouseReceiptStatement;